import {
  LoginRequestInterface,
  ChangePasswordRequestInterface,
  ForgotPasswordRequestInterface,
  ForgotPasswordVerifyRequestInterface,
  ResetPasswordRequestInterface,
} from '@/data/payload/contracts/AuthRequest'

export class LoginApiRequest implements LoginRequestInterface {
  private role: string
  private email: string
  private password: string

  constructor(role: string, email: string, password: string) {
    this.role = role
    this.email = email
    this.password = password
  }

  public toPayload(): string {
    const data = {
      role: this.role,
      username: this.email,
      password: this.password,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class ForgotPasswordRequest implements ForgotPasswordRequestInterface {
  private email: string

  constructor(email: string) {
    this.email = email
  }

  public toPayload(): string {
    return JSON.stringify({
      email: this.email,
    })
  }
}

export class ForgotPasswordVerifyRequest
  implements ForgotPasswordVerifyRequestInterface {
  private token: string

  constructor(token: string) {
    this.token = token
  }

  public toPayload(): string {
    return JSON.stringify({
      token: this.token,
    })
  }
}

export class ResetPasswordRequest implements ResetPasswordRequestInterface {
  private token: string
  private password: string
  private passwordConfirmation: string

  constructor(token: string, password: string, passwordConfirmation: string) {
    this.token = token
    this.password = password
    this.passwordConfirmation = passwordConfirmation
  }

  public toPayload(): string {
    return JSON.stringify({
      token: this.token,
      password: this.password,
      password_confirm: this.passwordConfirmation,
    })
  }
}

export class ChangePasswordApiRequest
  implements ChangePasswordRequestInterface {
  private passwordCurrent: string
  private password: string
  private passwordConfirm: string

  constructor(
    passwordCurrent: string,
    password: string,
    passwordConfirm: string
  ) {
    this.passwordCurrent = passwordCurrent
    this.password = password
    this.passwordConfirm = passwordConfirm
  }

  public toPayload(): string {
    const data = {
      password_current: this.passwordCurrent,
      password: this.password,
      password_confirm: this.passwordConfirm,
    }

    return JSON.stringify({
      ...data,
    })
  }
}
