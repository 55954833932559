var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('Modal',{attrs:{"visible":_vm.confirmationModal,"title":"Confirmation"}},[_c('div',{staticClass:"text-center",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('p',[_vm._v("Are you sure want to change the password?")])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"mr-3",attrs:{"buttonStyle":"outline"},on:{"click":function () {
            _vm.confirmationModal = false
          }}},[_vm._v("Cancel")]),_c('Button',{on:{"click":function () {
            _vm.onChangePassword()
          }}},[_vm._v("Yes, Change")])],1)]),_c('Modal',{attrs:{"visible":_vm.successModal,"title":"Success!","type":"success","body":"The Password successfully changed"},on:{"change":_vm.onCloseSuccessModal}}),_c('h2',{staticClass:"text-3xl font-semibold mb-8"},[_vm._v(_vm._s(_vm.controller.userEmail))]),_c('div',{staticClass:"flex flex-col w-1/2"},[_c('input',{attrs:{"type":"hidden","id":"user-text-field","autocomplete":"username"},domProps:{"value":_vm.controller.userEmail}}),_c('span',{staticClass:"text-xl font-semibold mb-6"},[_vm._v("Change Password")]),_c('SettingTextInput',{attrs:{"fieldLabel":"*Current Password","fieldDescription":"Your current password","id":"setting-current-password","name":"settingCurrentPassword","type":"password","autocomplete":"current-password","placeholder":"******","errorMessage":!_vm.$v.form.currentPass.required && _vm.hasChanged.currentPass ? 'field is required' : undefined},on:{"input":function () {
            _vm.hasChanged.currentPass = true
          }},model:{value:(_vm.form.currentPass),callback:function ($$v) {_vm.$set(_vm.form, "currentPass", $$v)},expression:"form.currentPass"}}),_c('SettingTextInput',{attrs:{"fieldLabel":"*New Password","fieldDescription":"Your new password","id":"setting-new-password","name":"settingNewPassword","type":"password","autocomplete":"new-password","placeholder":"******","errorMessage":!_vm.$v.form.newPass.required && _vm.hasChanged.newPass ? 'field is required' : undefined},on:{"input":function () {
            _vm.hasChanged.newPass = true
          }},model:{value:(_vm.form.newPass),callback:function ($$v) {_vm.$set(_vm.form, "newPass", $$v)},expression:"form.newPass"}}),_c('SettingTextInput',{attrs:{"fieldLabel":"*Confirm Password","fieldDescription":"Confirm your new password","id":"setting-confirm-password","name":"settingConfirmPassword","type":"password","autocomplete":"new-password","placeholder":"******","errorMessage":_vm.$v.form.confirmPass.$invalid && _vm.hasChanged.confirmPass ? 'field is required and must be same with new password' : undefined},on:{"input":function () {
            _vm.hasChanged.confirmPass = true
          }},model:{value:(_vm.form.confirmPass),callback:function ($$v) {_vm.$set(_vm.form, "confirmPass", $$v)},expression:"form.confirmPass"}})],1),_c('div',{staticClass:"flex flex-row justify-center mb-14"},[_c('Button',{staticClass:"px-12 py-3",attrs:{"disabled":_vm.$v.form.$invalid,"customPadding":""},on:{"click":function () {
          _vm.confirmationModal = true
        }}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }