import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { ChangePasswordApiRequest } from '@/data/payload/api/AuthRequest'
import store from '@/app/ui/store'
import { SettingPresenter } from '../presenters/SettingPresenter'
import {
  EventBus,
  EventBusConstants,
  LocalStorage,
} from '@/app/infrastructures/misc'

export interface SettingState {
  isLoading: boolean
  userEmail: string
}

@Module({ namespaced: true, store, name: 'settings', dynamic: true })
class SettingController extends VuexModule implements SettingState {
  private presenter: SettingPresenter = container.resolve(SettingPresenter)
  public isLoading = false
  public userEmail = LocalStorage.getLocalStorage(
    LocalStorage.LOGIN_IDENTITY,
    true
  )

  @Action({ rawError: true })
  public changePassword(form: {
    currentPass: string
    newPass: string
    confirmPass: string
  }) {
    this.setLoading(true)

    this.presenter
      .changePassword(
        new ChangePasswordApiRequest(
          form.currentPass,
          form.newPass,
          form.confirmPass
        )
      )
      .then(res => {
        EventBus.$emit(EventBusConstants.CHANGE_PASSWORD_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Change Password Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }
}

export default getModule(SettingController)
