




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Loading extends Vue {
  @Prop({ default: true } ) private showText!: boolean
  @Prop({ default: 'self-center animate-spin h-5 w-5 text-bgPrimary'} ) private customClass!: string
}
