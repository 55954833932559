
















































































































import { Component, Vue } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/SettingController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import SettingTextInput from '../components/SettingTextInput/index.vue'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    TextInput,
    Loading,
    Modal,
    SettingTextInput,
  },
})
export default class AccountSettingPage extends Vue {
  controller = controller
  confirmationModal = false
  successModal = false
  form = {
    currentPass: '',
    newPass: '',
    confirmPass: '',
  }
  hasChanged = {
    currentPass: false,
    newPass: false,
    confirmPass: false,
  }

  created() {
    EventBus.$on(EventBusConstants.CHANGE_PASSWORD_SUCCESS, () => {
      this.successModal = true
    })
  }

  @Validations()
  validations() {
    let validate = {
      form: {
        currentPass: { required },
        newPass: { required },
        confirmPass: { required, sameAsPassword: sameAs('newPass') },
      },
    }

    return validate
  }

  private onChangePassword() {
    this.confirmationModal = false
    controller.changePassword(this.form)
  }

  private onCloseSuccessModal() {
    this.confirmationModal = false
    this.successModal = false
    this.resetAll()
  }

  private resetAll() {
    this.form = {
      currentPass: '',
      newPass: '',
      confirmPass: '',
    }
    this.hasChanged = {
      currentPass: false,
      newPass: false,
      confirmPass: false,
    }
  }

  beforeDestroy() {
    EventBus.$off(EventBusConstants.CHANGE_PASSWORD_SUCCESS)
  }
}
